<script>
  import { _, t } from "svelte-i18n";
  import { router } from "tinro";
  import { getData, postData } from "../../utils/ApiUtils.svelte";
  import Loader from "../../components/Loader.svelte";
  import { onMount } from "svelte";

  import SvelteHeatmap from "svelte-heatmap";

  export let studentAccountId;

  let studentList = [];

  let studentRecordingListStatus = "loading";

  let heatMap;
  let heatMapData = {};

  onMount(async () => {
    getStudentList();
  });

  function getStudentList() {
    getData(
      `api/teacher/student-accounts/${studentAccountId}/recording-statistics?lastXDays=365`,
    )
      .then((data) => {
        console.log("studentList successful", data);

        studentList = data;
        studentRecordingListStatus = "ok";
        try {
          initHeatMap();
        } catch (error) {
          console.log("error initializing heatmap", error);
        }
      })
      .catch((error) => {
        studentRecordingListStatus = "error";
        console.log("error lessons", error);
      });
  }

  function getStartDateXMonthAgo(months) {
    // Create a new date object for the current date
    const currentDate = new Date();

    // Get the current month (0–11) and year
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Convert the current year and month into a total "months count"
    // Example: January 2024 => (2024 * 12) + 0
    const totalCurrentMonths = currentYear * 12 + currentMonth;

    // Subtract the given months
    const targetTotalMonths = totalCurrentMonths - months;

    // Convert back to a year and month
    const targetYear = Math.floor(targetTotalMonths / 12);
    const targetMonth = targetTotalMonths % 12; // Will be in range 0–11

    // Create the new date set to the 1st of that target month/year
    const targetDate = new Date(targetYear, targetMonth, 1);

    return targetDate;
  }

  function initHeatMap() {
    let heatMapData = studentList
      .map((sl) => {
        return {
          date: new Date(Date.parse(sl.date)),
          value: sl.count,
        };
      })
      .filter((i) => {
        return i.date instanceof Date;
      });
    console.log(heatMapData);

    // const startDate = new Date(Date.parse('2022-06-01T12:00:00.000Z'))
    const startDate = getStartDateXMonthAgo(12);
    // startDate.setMonth(startDate.getMonth() - 12);
    const endDate = new Date(Date.now());
    //const endDate = new Date(Date.parse("2022-08-02T00:00:00.000Z"));
    if (heatMap) {
      heatMap.$destroy();
    }
    heatMap = new SvelteHeatmap({
      props: {
        data: heatMapData,
        startDate: startDate,
        endDate: endDate,
        allowOverflow: false,
        view: "monthly",
        monthGap: 20,
        monthLabelHeight: 10,
      },
      target: document.querySelector("#heatMap"),
    });
  }
</script>

<div class="s-content-spacing">
  <div class="card shadow mb-4">
    <div class="card-body">
      {#if studentRecordingListStatus === "loading"}
        <Loader />
      {/if}
      <div id="heatMap" class="heatMap" />
    </div>
    <div />
  </div>
</div>
